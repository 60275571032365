html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevents unwanted horizontal scrolling */
}

.windowContainer {
  width: 100%;
  max-width: 100vw; /* Prevents horizontal expansion */
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
}

.blueSquare {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  background-color: rgb(66, 77, 81);
  position: relative;
}

.bannerText, .subText {
  position: relative;
  text-align: center;
  color: white;
}

.bannerText {
  font-size: 5vw;
}

.subText {
  font-size: 2vw;
}
