.video-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    
  }
  
  .video-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Change text color as needed */
    font-size: 4vw; /* Adjust font size as needed */
    z-index: 1; /* Ensure text appears above video */
  }
  
  .fullscreen-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-left-radius: 100px; /* Adjust the radius as needed */
    border-bottom-right-radius: 100px; /* Adjust the radius as needed */
  }

  .image-container {
    width: 100%;
    max-width: 800px; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center the image horizontally */
  }
  
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  