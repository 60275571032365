.upc {
    width: 315px;
    height: 550px;
    margin: 20px;
    border-radius: 25px;
    padding-bottom: 30px;
    background: rgb(225, 220, 220); 
    margin-top: 55px;
    margin-left: 5px;
}


.content img {
  width: 50%; 
  height: auto;
  border: 2px solid #000; 
  border-radius: 10px; 
  margin-top: 20px; 
}


.gradient {
    background: linear-gradient(to bottom, rgba(169, 15, 15, 0.75), rgba(221, 204, 204, 0)); 
    height: 200px;
    border-radius: 25px;
}

.gradient .gradient-image {
    width: 100%;
    height: auto;
    border-radius: 25px;
}

.profile-down img {
    height: 110px;

    margin-top: -75px;
    padding: 5px;
    background: rgba(201, 80, 80, 0.5);
}

.profile-title {
    font-size: 35px;
    font-weight: 600;
    font-family: 'montserrat, sans-serif';
}

.profile-description {
    padding: 20px 40px;
    font-size: 15px;
}

a {
    text-decoration: none;
    color: rgb(0, 0, 0);
}

.container {
    display: flex;
    justify-content: space-between; 

}

.banner-textBIO {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5vw;
}

@media screen and (max-width: 600px) {
    .tee{
  
      width: 100%; 
  
  
    }
    .card{
      width: 7.35em;
      border-radius: 2em;
      box-shadow: 0 0 2em rgba(0, 0, 0, .2);
      padding: 1em 1em;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 1em;
    }
    .card .imbBx {
      width: 6.5em;
      height: 7em;
      border-radius: 50%;
      border: 5px solid var(--color);
      padding: 2px;
      margin-bottom: 0em;
    }
  
    .card .imbBx img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  
    .card .content {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  
    .card .content .contentBx h3 {
      font-size: 0.8rem;
      font-weight: 500;
      position: relative;
      top: .2em;
    }
  
    .card .content .contentBx h3 .card__name {
      color: var(--color);
    }
  
    .card .content .sci {
      list-style: none;
      display: flex;
      margin-top: 0em;
      padding-left: 0;
    }
  
    .card .content .sci li {
      margin: 0 0.5em;
    }
  }

  .bio-container {
    width: 100vw; 
    height: 1800px;
    overflow: hidden;
    position: relative;
  }
  
  
  .banner {
    width: 100%;
    height: 300px; 
    background-color: #333; 
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .content {
    padding-top: 100px; 
    
  }
  
  .banner-textBIO {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.5vw;
  }
  
  .banner-text h2 {
    font-size: 2vw; 
    color: white; 
  }
  .subtitle {
    font-size: 2vw; 
  }