@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'montserrat', sans-serif; /* Set default font to Helvetica */
}

:root {
  --mainColor: #ffffff;
  --mainColorLight: #5767aa;
  --secondaryColor: rgb(235, 35, 36);
  --textColor: rgb(66, 77, 81);
}

header {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 105px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  transition: color 0.3s;
}

nav a:hover {
  color: var(--secondaryColor); /* Change text color to red on hover */
}

.profbutton {
  font-size: 16px;
  background: #e3e0e0;
  margin: auto 10px 10px 10px; /* Adjust margin to align buttons to the bottom */
  padding-top: 8px;
  padding-bottom: 10px;
  border-radius: 40px;
  color: black !important;
  line-height: 25px;
  align-self: flex-end; /* Align buttons to the bottom */
}

.dropdown {
  position: relative; /* Change float to position relative */
  display: inline-block; /* Add this line */
  margin: 0; /* Remove float and margin */
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: rgb(66, 77, 81);
  padding-top: 0px;
  margin-right: 30px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

.dropdown-content {
  display: none;
  position: absolute; /* Change position to absolute */
  top: 100%; /* Position dropdown below the button */
  left: 0; /* Align dropdown with the button */
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0px;
}

.dropdown-content .active {
  color: rgb(235, 35, 36);
}

.active {
  color: rgb(235, 35, 36);
}

.dropdown-content a {
  float: none;
  color: rgb(66, 77, 81);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: transparent; /* Keep background color transparent */
  color: rgb(235, 35, 36); /* Change text color on hover */
}

.dropdown:hover .dropdown-content {
  display: block;
}

.more-button {
  font-size: 20px;
  background: transparent;
  margin: auto 10px 10px 10px; /* Adjust margin to align buttons to the bottom */
  padding-top: 8px;
  padding-bottom: 10px;
  border-radius: 40px;
  color: rgb(74, 74, 74) !important;
  line-height: 25px;
  align-self: flex-end; /* Align buttons to the bottom */
}

.dropbuttons {
  position: relative; /* Add relative positioning */
  margin: auto -20px 0px 10px;
  font-size: 16px;
  padding: 0px 16px;
  border: none;
  
  background-color: rgb(255, 255, 255);
  color: lightslategray;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropbuttons1 {
  position: relative; /* Add relative positioning */
  margin: auto -20px 10px 10px;
  font-size: 16px;
  padding: 0px 16px;
  border: none;
  
  background-color: rgb(255, 255, 255);
  color: lightslategray;
  cursor: pointer;
  transition: background-color 0.3s;
}




.phone-number-btn {
  position: relative; /* Add relative positioning */
  margin: auto 10px 5px 40px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background-color: rgb(235, 35, 36);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.phone-number-btn:hover {
  background-color: #3e3e3e; /* Change the background color on hover as desired */
  color: white;
}

.email-btn {
  position: relative; /* Add relative positioning */
  margin: auto 10px 5px 20px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background-color: rgb(235, 35, 36);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.email-btn:hover {
  background-color: #3e3e3e; /* Change the background color on hover as desired */
  color: white; /* Change the background color on hover as desired */
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header .logo {
  
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100%);
  }

  header .responsive_nav {
    transform: translateY(0);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1rem;
    text-align: center; /* Center text */
    margin: -0.5rem 0; /* Add some margin for spacing */
  }
}
