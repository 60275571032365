
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* Variables */
:root {
  /* FONT */
  --font: 'Poppins', sans-serif;

  /* COLORS */
  --color: rgb(235, 35, 36);
  --bg-color: #ffffff;
}

/* Team section styles */
/* Team section styles */
.wrapper {
  font-family: var(--font);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: var(--bg-color);
  z-index: 3;
}

.card_Container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
}

.card1 {
  width: 17em; /* Adjust the width for uniformity */
  height: auto; /* Allow height to adjust based on content */
  background-color: #fff;
  border-radius: 1em; /* Soft rounded corners */
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.08); /* Subtle shadow for a clean look */
  padding: 0.5em 0.25em;
  display: flex;
  align-items: center;
    flex-direction: column;
  margin: 1.5em; /* Provide space between cards */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.card1.flipped {
  transform: rotateY(180deg);
  background-color: white; /* Make it blank white */
}


.card1.flipped:hover {
  transform: rotateY(180deg); /* No scaling or shadow on hover */
  box-shadow: none;
  cursor: default; /* Default cursor when flipped */
}


.card1:hover {
  transform: scale(1.05); /* Slight scale effect on hover */
  box-shadow: 0 0 3em rgba(0, 0, 0, 0); /* More intense shadow on hover */
  cursor: pointer; 
}

.card1 .imbBx {
  width: 100%;
  height: 17em; /* Adjust image height for uniformity */
  border-radius: 0.5em; /* Rounded corners for the image */
  overflow: hidden; /* Ensures the image doesn't overflow the container */
}

.card1 .imbBx img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container while maintaining aspect ratio */
  border-radius: 0.5em; /* Match the container's rounded corners */
}


.blank-side {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 0.5em;
}

.card_Container .card1:nth-child(2) {
  transform: translateY(30px); /* Push the middle card down to create the V-shape */
}


/* Card flipping container */
.card1 {
  width: 17em;
  height: 20em;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.08);
  margin: 1.5em;
  perspective: 1000px;
  position: relative;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* When card is flipped */
.card1.flipped .card-inner {
  transform: rotateY(180deg);
}

/* Front and back faces */
.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1em;
}

/* Front of the card with the image */
.card-front {
  z-index: 2;
  transform: rotateY(0deg);
}

.card-front .imbBx {
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
  overflow: hidden;
}

.card-front .imbBx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5em;
}

/* Back of the card with the content */
.card-back {
  transform: rotateY(180deg);
  background-color: white;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card1__name {
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
  margin-bottom: 0.5em;
}

.card1__role {
  font-size: 1rem;
  color: rgb(66, 77, 81);
}

/* Back content */
.card-back .content-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotateY(180deg); /* Fix the text showing backwards */
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  .card1 {
    width: 100%;
    margin: 1em 0; /* Adjust margins for smaller screens */
    border-radius: 0.5em; /* Slightly reduce border-radius for mobile */
  }

  .card1 .imbBx {
    height: 15em; /* Reduce image height for mobile */
  }
}


@media screen and (max-width: 600px) {
  .tee{
    width: 100%; 
   
  }
  .card_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .upc {
      width: 100%; 
      height: 100%; 

  }
  .wrapper {
    font-family: var(--font);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    background-color: var(--bg-color);
    z-index: 3;
  }

  
  .card1{
    width: 10em;
    border-radius: 1em;
    height: 12em; 
    
    padding: 0em 0em;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0.5em 1.5em;
  }
  .card1 .imbBx {
    width: 10em;
    height: 12em;
    border-radius: 1em;
    padding: 2px;
    margin-bottom: -1em;
  }

  .card1 .imbBx img {
    width: 100%;
    height: 100%;
    
    object-fit: cover;
  }

  .card1 .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card1 .content .contentBx h3 {

    font-size: 0.8rem;
    font-weight: 500;
    position: relative;
    top: .2em;
  }

  .card1 .content .contentBx h3 .card1__name {
    color: var(--color);
  }

  .card1 .content .sci {
    list-style: none;
    display: flex;
    margin-top: 0em;
    padding-left: 0;
  }

  .card1 .content .sci li {
    margin: 0 0.5em;
  }
}

