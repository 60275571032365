.searchable-list-container {
  display: flex;
  align-items: left;
  justify-content: left;
  border-color: white;
  border: none; /* Remove border */
}

.search-input {
  width: 315%;
  padding: 4px;
  margin-bottom: 10px;
  border: 1px solid #0c0c0c;
  border-radius: 4px;
}

.list {
  list-style: none;
  padding: 0;
  width: 315%; /* Set width to 100% */
}

.list-item {
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis for overflowed text */
  background-color: #333; /* Darker background color */
  color: #fff; /* White text color */
  border-radius: 4px;
  padding: 6px; /* Increased padding for better readability */
  margin-bottom: 0px;
  text-align: left; /* Align text to the left */
}

.list-item:hover {
  background-color: #007bff; /* Darker hover background color */
}
/* list.css */

.arrow {
  border: solid gray;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
}



.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}





