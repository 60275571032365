.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgb(255, 255, 255, 0);
}

.content {
  width: 100%;
  margin: 0 auto;
}

.image-container {
  display: flex;
  overflow-x: auto;
  background-color: #d8b578;
  padding: 15px;
}

.image-container img {
  scroll-snap-align: start;
  width: 300px;
  height: auto;
  margin-right: 0px;
}

.image-wrapper {
  flex: 1;
  margin: 0 2px;
}

.image-wrapper img {
  width: 50%;
  height: auto;
}

.banner-container {
  position: relative;
}

.banner-text {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  font-size: 4vw;
  text-align: center;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.slogan {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(0, 0, 0);
  font-size: 2.5vw;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
}

.slogan.show {
  opacity: 1;
}

.butt {
  display: inline-block;
  width: 45%;
  border: 4px solid white;
  border-radius: 7px;
  padding: 5px 10px;
  background-color: white;
  margin-top: 50px;
  margin-right: 10px;
  font-size: 18px;
  color: rgb(235, 35, 36);
  text-decoration: none;
}

.butt:hover {
  color: white;
  background-color: rgb(235, 35, 36);
  border-color: rgb(235, 35, 36);
}

.butt a {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 5px 10px;
}

.butt a:hover {
  color: white;
}

.cursor {
}

.loader-container {
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content-wrapper {
  display: block;
}

.loading .content-wrapper {
  display: none;
}

#typewriter {
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid;
  background-color: hsla(0, 0%, 100%, 1);
  width: auto;
  animation: caret 0.5s step-end infinite;
}

@keyframes caret {
  50% { border-color: transparent; }
}
