.second-section {
  background-color: #f0f0f0; /* Gray background color */
  padding: 20px; /* Adjust padding as needed */
  position: relative; /* Position relative for absolute positioning inside */
  min-height: 1500px; /* Minimum height to ensure it grows with content */
}

.text-box {
  background-color: white; /* White background color */
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Shadow behind the text box */
  padding: 20px; /* Adjust padding as needed */
  font-family: 'Ubuntu', sans-serif; /* Set font to Ubuntu */
  max-width: 40%; /* Maximum width of the text box */
  position: absolute; /* Position absolute for custom placement */
  left: 20px; /* Distance from the left */
  text-align: left;
  top: 5%; /* Distance from the top */
}

.title {
  font-size: 3vw; /* Relative font size for the title */
  margin-bottom: 20px; /* Add margin between title and subtext */
}

.subtext {
  font-size: 2vw; /* Relative font size for the subtext */
  color: #808080; /* Gray font color */
}

.text-box2 {
  background-color: white; /* White background color */
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Shadow behind the text box */
  padding: 20px; /* Adjust padding as needed */
  font-family: 'Ubuntu', sans-serif; /* Set font to Ubuntu */
  max-width: 40%; /* Maximum width of the text box */
  position: absolute; /* Position absolute for custom placement */
  top: 30%; /* Distance from the top */
  text-align: left;
  left: 20px; /* Distance from the right */
}

.title2 {
  font-size: 3vw; /* Relative font size for the title */
  margin-bottom: 20px; /* Add margin between title and subtext */
}

.titleblue {
  font-size: 3vw;
  margin-bottom: 30px;
  color: #45B0DA;
}

.subtext2 {
  font-size: 2vw; /* Relative font size for the subtext */
  color: #808080; /* Gray font color */
}

.brainsway-image {
  max-width: 170%; /* Set maximum width to 100% of the container */
  max-height: 170%;
  position: relative; /* Position relative within the .second-section container */
  z-index: 1; /* Ensure the image stays behind the text boxes */    
  position: absolute; /* Position absolute for custom placement */
  right: -150%; /* Distance from the left */
  top: -40%; /* Distance from the top */
}

.brainsway-image2 {
  max-width: 80%; /* Set maximum width to 30% of the container */
  position: relative; /* Position absolute for custom placement */
  top: 20%; /* Center vertically */
  right: -20%; /* Distance from the right */
  transform: translateY(-50%); /* Center vertically */
  z-index: 1;
}

.coil-image {
  max-width: 120%; /* Set maximum width to 30% of the container */
  position: absolute; /* Position absolute for custom placement */
  top: 65%; /* Center vertically */
  right: -105%; /* Distance from the right */
  transform: translateY(-50%); /* Center vertically */
  z-index: 0;
}

.text-box3 {
  background-color: white; /* White background color */
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Shadow behind the text box */
  padding: 20px; /* Adjust padding as needed */
  font-family: 'Ubuntu', sans-serif; /* Set font to Ubuntu */
  width: 90%; /* Maximum width of the text box */
  position: absolute; /* Position absolute for custom placement */
  top: 60%; /* Distance from the top */
  text-align: left;
  left: 20px; /* Distance from the right */
}

.title3 {
  font-size: 3vw; /* Relative font size for the title */
  margin-bottom: 30px; /* Add margin between title and subtext */
}

.subtext3 {
  font-size: 2vw; /* Relative font size for the subtext */
  color: #808080; /* Gray font color */
}

.youtube-video {
  max-width: 100%; /* Set the maximum width */
  max-height: 100%;
  position: absolute; /* Position absolute for custom placement */
  bottom: 14%; /* Distance from the bottom */
  right: 3%; /* Distance from the right */
}





@media (min-width: 300px) and (max-width: 400px) {
  .second-section {
    min-height: 1300px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 30%;
  }

  .text-box3 {
    top: 56%;
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .second-section {
    min-height: 1300px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 30%;
  }

  .text-box3 {
    top: 55%;
  }
}

@media (min-width: 701px) and (max-width: 800px) {
  .second-section {
    min-height: 1800px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 30%;
  }

  .text-box3 {
    top: 55%;
  }
}
@media (min-width: 801px) and (max-width: 1000px) {
  .second-section {
    min-height: 1900px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 30%;
  }

  .text-box3 {
    top: 60%;
  }
}
@media (min-width: 1001px) and (max-width: 1250px) {
  .second-section {
    min-height: 1900px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 35%;
  }

  .text-box3 {
    top: 70%;
  }
}
@media (min-width: 1251px) and (max-width: 1400px) {
  .second-section {
    min-height: 1900px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 35%;
  }

  .text-box3 {
    top: 70%;
  }
}
@media (min-width: 1251px) and (max-width: 1400px) {
  .second-section {
    min-height: 2200px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 40%;
  }

  .text-box3 {
    top: 70%;
  }
}

@media (min-width: 1401px) and (max-width: 1600px) {
  .second-section {
    min-height: 2700px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 40%;
  }

  .text-box3 {
    top: 70%;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  .second-section {
    min-height: 2700px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 40%;
  }

  .text-box3 {
    top: 70%;
  }
}
@media (min-width: 1900px) {
  .second-section {
    min-height: 4000px; /* Use minimum height to ensure content displays properly */
  }
  .text-box2 {
    top: 45%;
  }

  .text-box3 {
    top: 85%;
  }
}
