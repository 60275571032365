.upc1 {
    width: 320px; 
    height: 500px; 
    margin: 20px;
    border-radius: 25px;
    padding-bottom: 20px; 
    background: rgb(225, 220, 220); 
}

.gradient {
    background: linear-gradient(to bottom, rgba(169, 15, 15, 0.75), rgba(221, 204, 204, 0)); 
    height: 200px;
    border-radius: 25px;
}

.profile-down1 img {
    height: 110px;
    border-radius: 100px 100px 5 20; 
    margin-top: -75px;
    padding: 5px;
    background: rgba(201, 80, 80, 0.5);
}

.profile-title1 {
    font-size: 35px;
    font-weight: 600;
    font-family: 'goldenbook, serif';
}

.profile-description1 {
    padding: 10px 20px; 
    font-size: 13px; 
}

.profbutton {
    display: inline-block;
    border: 2px solid red; 
    border-radius: 5px; 
    padding: 5px 10px; 
    color: red; 
}

.profbutton:hover {
    background-color: #eeeeee; 
    border-color: #7d7d7d;
}

.container1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.mapContainer {
    height: 80px; 
}

@media screen and (max-width: 800px) {
    .container1 {
        flex-direction: column;
        align-items: center; 
    }

    .upc1 {
        width: 100%; 
        height: auto; 
        max-width: 300px; 
        margin-bottom: 135px; 
    }
}
