@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.carousel {
  overflow: hidden;
  width: 2500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 750px;
  background-color: #d8b578;
}

.carousel-item-text {
  font-size: 1.15rem;
  margin: 40px 0 40px 0;
  padding: 0 20px 0 20px;
  white-space: normal;
  color: #617d98;
}
.carousel-img {
  width: 350px;
  padding: 0 20px 0 20px;
}

.carousel-buttons {
  display: flex;
  justify-content: space-evenly;
}

.button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.indicators {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.indicator-buttons {
  border: none;
  cursor: pointer;
}

.indicators > button {
  margin: 5px;
  background: none;
}

.indicator-symbol {
  color: #d8b578;
}

.indicator-symbol-active {
  color: #d8b578b3;
}
.card{
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 10px;
  box-shadow: 5px 5px 5px hsl(0, 0%, 0%, 0.1);
  padding: 20px;
  margin: 10px;
  text-align: center;
  max-width: 250px;
  display: inline-block;
}
.card .card-image{
  max-width: 60%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}
.card .card-title{
  font-family: Arial, sans-serif;
  margin: 0;
  color: hsl(0, 0%, 20%);
}
.card .card-text{
  font-family: Arial, sans-serif;
  color: hsl(0, 0%, 30%);
}





